import { PureComponent } from 'react';

import { GLOBAL } from 'saddlebag-browser';

import BpkPanel from '@skyscanner/backpack-web/bpk-component-panel';

import ScanQR from './ScanQR';
import EVENTS from './events';
import STRINGS from './strings';
import { getBrowserInfo, trackEvent } from './utils';

import type { StringTranslations } from '@skyscanner-internal/falcon-shared-types/types/components';

import STYLES from './ScanForApp.module.scss';

const $window = GLOBAL.getWindow();

type ScanForAppProps = {
  strings: StringTranslations;
  deeplinkUrl: string;
};

class ScanForApp extends PureComponent<ScanForAppProps> {
  componentDidMount() {
    getBrowserInfo($window.navigator.userAgent);
    trackEvent(EVENTS.COMPONENT_LOADED);
  }

  render() {
    const { deeplinkUrl, strings = STRINGS } = this.props;

    return (
      <BpkPanel className={STYLES.ScanForApp} padded={false}>
        <ScanQR strings={strings} deeplinkUrl={deeplinkUrl} />
      </BpkPanel>
    );
  }
}

export default ScanForApp;
