import type { ReactNode } from 'react';

import STYLES from './PanelContainer.module.scss';

type PanelProps = {
  children: ReactNode;
  imageSrc: string;
  isPrimary: boolean;
};

const getGridStyle = (isPrimary: boolean) =>
  isPrimary ? STYLES.PanelContainer__gridPrimary : STYLES.PanelContainer__grid;

const PanelContainer = ({
  children,
  imageSrc = '',
  isPrimary = false,
}: PanelProps) => (
  <div className={STYLES.PanelContainer__container}>
    {isPrimary && (
      <div
        className={STYLES.PanelContainer__background}
        style={{ backgroundImage: `url(${imageSrc})` }}
      />
    )}
    <div className={getGridStyle(isPrimary)}>
      <div className={STYLES['PanelContainer__gridRow--normal']}>
        {children}
      </div>
    </div>
  </div>
);

export default PanelContainer;
