import FramedScreenShot from '../FramedScreenshot';

import STYLES from './ScreenshotsWidget.module.scss';

type ScreenshotsWidgetProps = {
  screenshotsSrc: { ios: string; android: string };
};

const ScreenshotsWidget = ({ screenshotsSrc }: ScreenshotsWidgetProps) => (
  <div className={STYLES.ScreenshotsWidget__container}>
    <FramedScreenShot
      containerStyle={STYLES['ScreenshotsWidget__image--iphone']}
      screenshot={{
        type: 'iphone',
        src: screenshotsSrc.ios,
        width: 277,
        height: 600,
      }}
    />
    <FramedScreenShot
      containerStyle={STYLES['ScreenshotsWidget__image--android']}
      screenshot={{
        type: 'android',
        src: screenshotsSrc.android,
        width: 300,
        height: 600,
      }}
    />
  </div>
);

export default ScreenshotsWidget;
