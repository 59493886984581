import BpkImage from '@skyscanner/backpack-web/bpk-component-image';

import STYLES from './FramedScreenshot.module.scss';

type FramedScreenShotProps = {
  containerStyle: string;
  screenshot: {
    height: number;
    src: string;
    type: string;
    width: number;
  };
};

type PhoneStyle = {
  frame: {
    class: string;
    height: number;
    src: string;
    type: string;
    width: number;
  };
  screenshotClass: string;
  containerClass: string;
};

const PHONE: { [key: string]: any } = {
  iphone: {
    frame: {
      width: 302,
      height: 600,
      class: STYLES['Screenshot__frame--iphone'],
      src: 'https://content.skyscnr.com/m/7699b400b5ebab85/original/iPhone-Frame.png',
    },
    screenshotClass: STYLES['Screenshot__image--iphone'],
    containerClass: STYLES['Screenshot__container--iphone'],
  },
  android: {
    frame: {
      width: 310,
      height: 600,
      class: STYLES['Screenshot__frame--android'],
      src: 'https://content.skyscnr.com/m/10c9f3106de53b76/original/Google-Pixel-2-XL-Just-Black.png',
    },
    screenshotClass: STYLES['Screenshot__image--android'],
    containerClass: STYLES['Screenshot__container--android'],
  },
};

const FramedScreenshot = ({
  containerStyle,
  screenshot,
}: FramedScreenShotProps) => {
  const phoneStyle: PhoneStyle = PHONE[screenshot.type];
  return (
    <div className={`${containerStyle} ${phoneStyle.containerClass}`}>
      <BpkImage
        altText=""
        aspectRatio={phoneStyle.frame.width / phoneStyle.frame.height}
        className={phoneStyle.frame.class}
        src={phoneStyle.frame.src}
      />
      <BpkImage
        altText=""
        aspectRatio={screenshot.width / screenshot.height}
        className={phoneStyle.screenshotClass}
        src={screenshot.src}
      />
    </div>
  );
};

export default FramedScreenshot;
