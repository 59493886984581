import BpkText from '@skyscanner/backpack-web/bpk-component-text';

import STYLES from './MainText.module.scss';

type MainTextProps = {
  descriptionString?: string;
  subtitleString: string;
  titleString: string;
};

const MainText = ({
  descriptionString = '',
  subtitleString,
  titleString,
}: MainTextProps) => (
  <div className={STYLES.MainText__container}>
    <BpkText className={STYLES['MainText__text--title']}>{titleString}</BpkText>
    <BpkText className={STYLES['MainText__text--subtitle']}>
      {subtitleString}
    </BpkText>
    <BpkText className={STYLES['MainText__text--description']}>
      {descriptionString}
    </BpkText>
  </div>
);

export default MainText;
