import bowser from 'bowser';
import { GLOBAL } from 'saddlebag-browser';
import observer from 'saddlebag-observer';

const $window = GLOBAL.getWindow();
let browserInfo = {};

const getBrowserInfo = (userAgent = '') => {
  try {
    const parsed = bowser.getParser(userAgent);

    const browser = parsed.getBrowser();
    const os = parsed.getOS();
    const platform = parsed.getPlatform();

    browserInfo = {
      browserName: browser.name,
      browserVersion: browser.version,
      osName: os.name,
      osVersion: os.version,
      platformType: platform.type,
      platformVendor: platform.vendor,
    };
  } catch (e) {
    browserInfo = {};
  }
};

const trackEvent = (eventName: string, eventParams = {}) => {
  const params: { origin: string; error?: string } = {
    origin: $window.location.origin,
    ...browserInfo,
    ...eventParams,
  };

  observer.publish('tracking-event', {
    mixpanel: [
      {
        name: eventName,
        props: params,
      },
    ],
  });
};

export { getBrowserInfo, trackEvent };
